import { createSlice } from "@reduxjs/toolkit";

/**
 * customizedPosmL1ListDTOs:{
        customizedPosmCode:'',
        customizedPosmOrderCode:'',
        displayEndDate: "",
        displayStartDate: "",
        accountItemCode:'',
        accountItemName:'',
        notes: "",
        singleStoreCustomizationDescListDTOs: [
          { pictureUrl: "", pictureType: "" },
        ],
        subcategoryPosmListDTOs: [
          {
            customizedSubposmCode: "",
            brandCode: "",
            qty: 0,
            offerAmountTax: "",
          },
        ],
      },
 */
export const StoreSlice = createSlice({
  name: "storeSingle",
  initialState: {
    outletDetail: {},
    allocPic: [],
    confirmQuotePic: [],
    brandCodes: [], //如果修改了品牌，必须重置专项项目
    customizedPosmSupplementaryPicDTOs: [], //补充图片
    outletPictureDTOs: [], //补充照片
    customizedPosmL1ListDTOs: [],
    baseInfo: {
      //-----------基础数据----------
      activityName: "", //活动名称
      activityStartDate: "", //开始日期
      activityEndDate: "", //结束日期
      planDesc: "",
      // paymentNotes: "", //付款支付说明字段删除
    },
    costInfo: {
      //------------费用信息-------------
      useFeeStartDate: "",
      useFeeEndDate: "",
      ccL2Code: "", //二级预算中心
      projectCode: "", //专项code
      supplierCode: "", //供应商code
      demandDeliveryDate: "",
      receivedTotalAmountTax: "",
      budgetAmountNotax: 0,
    },
    // 物料主单信息
    posmOrderDTO: {},
    kpiInfoDTO: {},
    isCreate: true, // 是否是创建单子
    // 补充说明
    remark: "",
  },
  reducers: {
    setPayment: (state, action) => {
      const { salesOrgCode, salesOrgName } = action.payload;
      state.costInfo.salesOrgCode = salesOrgCode;
      state.costInfo.salesOrgName = salesOrgName;
    },
    setBo3: (state, action) => {
      const { boName3, boId3 } = action.payload;
      state.costInfo.boName3 = boName3;
      state.costInfo.boId3 = boId3;
    },
    setBo2: (state, action) => {
      const { boId2, boName2 } = action.payload;
      console.log(boId2, boName2);
      state.costInfo.boId2 = boId2;
      state.costInfo.boName2 = boName2;
    },
    setKpiInfoDtoInfo: (state, action) => {
      state.kpiInfoDTO = action.payload;
    },
    setOutletDetail: (state, action) => {
      state.outletDetail = action.payload;
    },
    setCustomizedPosmSupplementaryPicDTOs: (state, action) => {
      state.customizedPosmSupplementaryPicDTOs = action.payload;
    },
    setOutletPicture: (state, action) => {
      state.outletPictureDTOs = action.payload;
    },
    setSubcategoryPosmListDTOs: (state, action) => {
      state.customizedPosmL1ListDTOs.subcategoryPosmListDTOs = action.payload;
    },
    setPosmDtoList: (state, action) => {
      state.customizedPosmL1ListDTOs = action.payload;
    },
    setBaseInfo: (state, action) => {
      state.baseInfo = action.payload;
    },
    setChangeBrandsInfo: (state, action) => {
      state.brandCodes = action.payload;
    },
    setCostInfo: (state, action) => {
      state.costInfo = action.payload;
    },
    setPosmOrderDTO: (state, action) => {
      state.posmOrderDTO = action.payload;
    },
    setIsCreate: (state, action) => {
      state.isCreate = action.payload;
    },
    setRemark: (state, action) => {
      state.remark = action.payload;
    },
    setAllocPic: (state, action) => {
      state.allocPic = action.payload;
    },
    setConfirmQuotePic: (state, action) => {
      state.confirmQuotePic = action.payload;
    },
    resetAll: (state, action) => {
      state.costInfo = {};
      state.allocPic = [];
      state.confirmQuotePic = [];
      state.outletPictureDTOs = [];
      state.baseInfo = {};
      state.customizedPosmL1ListDTOs = [];
      state.posmOrderDTO = {};
      state.customizedPosmSupplementaryPicDTOs = []; //补充图片
      state.outletPictureDTOs = []; //补充照片
      state.customizedPosmL1ListDTOs = [];
      state.brandCodes = [];
      state.kpiInfoDTO = {};
      state.remark = "";
    },
  },
});
// 导出state
export const storeSingle = (state) => state.storeSingle;
// 导出actions
export const {
  setBo3,
  setAllocPic,
  setBo2,
  setPayment,
  setOutletDetail,
  setKpiInfoDtoInfo,
  setConfirmQuotePic,
  setCostInfo,
  setBaseInfo,
  setOutletPicture,
  setPosmDtoList,
  setSubcategoryPosmListDTOs,
  setPosmOrderDTO,
  setIsCreate,
  setRemark,
  resetAll,
  setChangeBrandsInfo,
  setCustomizedPosmSupplementaryPicDTOs,
} = StoreSlice.actions;
export default StoreSlice.reducer;
